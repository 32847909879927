<template>
  <div class="box">
    <div class="info">
      <div class="left">
        <div class="situation shadow">
          <div class="title">数据情况</div>
          <div class="list">
            <div class="item">
              <div class="text">
                <span class="num">{{ title[0].num }}/{{ title[1].num }}</span>
                <span class="txt">项目/有效(个)</span>
              </div>
              <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675239051761ce.png" alt="" />
            </div>
            <div class="item">
              <div class="text">
                <span class="num">{{ title[2].num }}</span>
                <span class="txt">自助注册(个)</span>
              </div>
              <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675239030533ce.png" alt="" />
            </div>
            <div class="item">
              <div class="text">
                <span class="num">{{ title[3].num }}</span>
                <span class="txt">后台创建(个)</span>
              </div>
              <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675238810616ce.png" alt="" />
            </div>
            <div class="item">
              <div class="text">
                <span class="num">{{ title[4].num }}</span>
                <span class="txt">停用(个)</span>
              </div>
              <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675239023323ce.png" alt="" />
            </div>
          </div>
        </div>
        <div class="recommend shadow">
          <div class="title">
            <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675239908844ce.png" alt="" />
          </div>
          <div class="list">
            <div class="item" @click="$router.push('/admin/project')">
              <img class="icon" src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675240454363ce.png" alt="" />
              <div class="text">
                <span class="tit">项目管理</span>
                <span class="txt">管理所有网站项目</span>
              </div>
              <img class="img" src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675240717860ce.png" alt="" />
            </div>
            <div class="item" @click="$router.push('/admin/project')">
              <img class="icon" src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675240526225ce.png" alt="" />
              <div class="text">
                <span class="tit">域名审核</span>
                <span class="txt">独立域名绑定，审核域名</span>
              </div>
              <img class="img" src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675240737923ce.png" alt="" />
            </div>
            <div class="item" @click="$router.push('/admin/agent')">
              <img class="icon" src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675240469122ce.png" alt="" />
              <div class="text">
                <span class="tit">代理管理</span>
                <span class="txt">代理用户、分销商配置</span>
              </div>
              <img class="img" src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675240724251ce.png" alt="" />
            </div>
            <div class="item" @click="$router.push('/admin/money')">
              <img class="icon" src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675240548912ce.png" alt="" />
              <div class="text">
                <span class="tit">财务管理</span>
                <span class="txt">财务充值提现配置</span>
              </div>
              <img class="img" src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675240730357ce.png" alt="" />
            </div>
          </div>
        </div>
        <div class="store">
          <div class="new shadow">
            <div class="title">
              <span>新增项目</span>
            </div>
            <div class="list">
              <el-table :data="list" height="450px" @sort-change="changeTableSort">
                <el-table-column prop="name" label="项目名称">
                  <div slot-scope="scope" class="name">
                    <img style="width: 36px; height: 36px" :src="scope.row.cover" alt="" />
                    <span style="width: 100px">{{ scope.row.name }}</span>
                  </div>
                </el-table-column>
                <el-table-column prop="userInfo" label="账号">
                  <div slot-scope="scope" class="phone" v-if="scope.row.userInfo.length > 0">
                    <span>{{ scope.row.userInfo[0].username }}</span>
                  </div>
                </el-table-column>
                <el-table-column prop="add_time" label="注册时间">
                  <span slot-scope="scope">{{ scope.row.add_time | date('Y-m-d H:i') }}</span>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="ranking shadow">
            <div class="title">
              <span>官网排行</span>
            </div>
            <div class="list">
              <el-table :data="listRanking" height="450px" @sort-change="changeTableSort">
                <el-table-column prop="type" label="Top" align="center" width="80px">
                  <div slot-scope="scope">
                    <img v-if="scope.$index == 0" style="width: 28px; height: 28px" src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675240710474ce.png" alt="" />
                    <img v-else-if="scope.$index == 1" style="width: 28px; height: 28px" src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675240705312ce.png" alt="" />
                    <img v-else-if="scope.$index == 2" style="width: 28px; height: 28px" src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675240701110ce.png" alt="" />
                    <span v-else>{{ scope.$index + 1 }}</span>
                  </div>
                </el-table-column>
                <el-table-column prop="name" label="项目名称">
                  <div slot-scope="scope" class="name">
                    <!-- <img style="width: 36px; height: 36px; border-radius: 50%" :src="scope.row.img" alt="" /> -->
                    <span style="width: 100px">{{ scope.row.name }}</span>
                  </div>
                </el-table-column>

                <el-table-column prop="look" label="访问量"></el-table-column>
                <el-table-column prop="add_time" label="注册时间">
                  <span slot-scope="scope">{{ scope.row.add_time | date('Y-m-d H:i') }}</span>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="top">
          <span class="come">您好,欢迎登录</span>
          <span class="name">智摆企业官网</span>
          <div class="text">
            您已成为尊贵会员
            <span>VIP</span>
            特权 享有
            <span>全部</span>
            功能使用
          </div>
        </div>
        <div class="vip-info">
          <div class="list">
            <div class="item">
              <div class="item-l">
                <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675233934548ce.png" alt="" />
                <span>项目类型</span>
              </div>
              <div class="item-r">
                <div class="type">官网网站DIY</div>
              </div>
            </div>
            <div class="item">
              <div class="item-l">
                <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675233944747ce.png" alt="" />
                <span>授权域名</span>
              </div>
              <div class="item-r">
                <div class="txt">当前域名</div>
              </div>
            </div>
            <div class="item">
              <div class="item-l">
                <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675233956038ce.png" alt="" />
                <span>授权时间</span>
              </div>
              <div class="item-r">
                <div class="txt">永久</div>
              </div>
            </div>
            <div class="item">
              <div class="item-l">
                <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675233940073ce.png" alt="" />
                <span>服务到期时间</span>
              </div>
              <div class="item-r">
                <div class="txt">永久</div>
              </div>
            </div>
          </div>
          <div class="button">联系官方( QQ: 270315475 )</div>
          <div class="intro">
            <div class="tit">
              <div class="line"></div>
              <span>永久尊享会员专享</span>
              <div class="line"></div>
            </div>
            <div class="txt">尊享特权</div>
            <div class="intro-list">
              <div class="item">
                <div class="icon">
                  <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675234763026ce.png" alt="" />
                </div>
                <span>客服</span>
                <span>客服服务</span>
              </div>
              <div class="item">
                <div class="icon">
                  <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675235029849ce.png" alt="" />
                </div>
                <span>折扣</span>
                <span>超值折扣</span>
              </div>
              <div class="item">
                <div class="icon">
                  <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675234751674ce.png" alt="" />
                </div>
                <span>反馈</span>
                <span>建议反馈</span>
              </div>
              <div class="item">
                <div class="icon">
                  <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675234732410ce.png" alt="" />
                </div>
                <span>新功能</span>
                <span>首发内测</span>
              </div>
            </div>
            <div class="more" @click="$router.push('/admin/upgrade/license')">
              <span>查看更多</span>
              <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675234772480ce.png" alt="" />
            </div>
          </div>
        </div>
        <div class="journal">
          <div class="title">
            <span class="text">更新日志</span>
            <div class="more" @click="$router.push('/admin/upgrade/journal')">
              <span>查看更多</span>
              <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675234772480ce.png" alt="" />
            </div>
          </div>
          <div class="list">
            <div class="item">
              <div class="title">
                <span class="ban">1.2.5</span>
                <span class="time">2023-01-05 14:00:51</span>
              </div>
              <div class="text">新增页面通用的30+种展示卡片；优化动画、界面视觉冲击感；页面跳转问题、板块问题；已知的问题bug；</div>
            </div>
            <div class="item">
              <div class="title">
                <span class="ban">1.1.12</span>
                <span class="time">2022-11-12 09:20:35</span>
              </div>
              <div class="text">新增卡片50种展示卡片；新增特色功能20种功能；新增帮助中心功能模块；新增特色跳转功能；新增各类产品展示26种；</div>
            </div>
            <div class="item">
              <div class="title">
                <span class="ban">1.0.3</span>
                <span class="time">2022-10-02 12:13:25</span>
              </div>
              <div class="text">新增企业模块，新增企业产品信息、新增售前售后内容；修复已知问题</div>
            </div>
            <div class="item">
              <div class="title">
                <span class="ban">0.8.1</span>
                <span class="time">2022-08-11 15:21:19</span>
              </div>
              <div class="text">新增模板中心，新增公司内容补充；新增头部尾部特色板块；优化bug；</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/cloud'

export default {
  data() {
    return {
      title: [
        {
          num: 0
        },
        {
          num: 0
        },
        {
          num: 0
        },
        {
          num: 0
        },
        {
          num: 0
        }
      ],
      list: [],
      listRanking: []
    }
  },
  created() {
    this.getNums()
    this.loadList()
    this.getList()
  },
  methods: {
    // 获取数量
    getNums() {
      this.title.map(async (item, index) => {
        const res = await http.GET('/jw-admin', {
          module: 'project',
          operation: 'getProjectNums',
          index: index
        })
        return (item.num = res.total)
      })
    },
    // 获取项目列表
    async loadList() {
      const res = await http.GET('/jw-admin', {
        module: 'project',
        operation: 'getProjectNew'
      })
      this.list = res
    },
    async getList() {
      const res = await http.GET('/jw-admin', {
        module: 'project',
        operation: 'getProjectRanking'
      })
      this.listRanking = res
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  padding: 20px;
  height: calc(100vh - 60px);
  background: #f6f9fc;
  .info {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    .left {
      width: 70%;
      margin-right: 20px;
      .shadow {
        background: #ffffff;
        box-shadow: 0px 2px 9px 0px rgba(105, 116, 231, 0.05);
      }
      .situation {
        margin-bottom: 10px;
        padding: 9px 0 15px 14px;
        .title {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 22px;
          margin-bottom: 7px;
        }
        .list {
          display: flex;
          .item {
            width: 248px;
            height: 76px;
            background: linear-gradient(180deg, #ecf1ff 0%, #ffffff 100%);
            display: flex;
            align-items: center;
            margin-right: 17px;
            padding: 0 25px;
            .text {
              flex: 1;
              display: flex;
              flex-direction: column;
              .num {
                font-size: 24px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 33px;
              }
              .txt {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 17px;
                margin-top: 1px;
              }
            }
            > img {
              width: 46px;
              height: 46px;
            }
          }
        }
      }
      .recommend {
        width: 100%;
        padding: 14px 0 14px 15px;
        .title {
          margin-bottom: 25px;
          > img {
            width: 62px;
            height: 16px;
          }
        }
        .list {
          display: flex;
          .item {
            cursor: pointer;
            width: 281px;
            height: 97px;
            display: flex;
            align-items: center;
            position: relative;
            margin-right: 16px;
            background: linear-gradient(136deg, #ff3b00 0%, #ff7872 100%);
            &:nth-child(2n) {
              background: linear-gradient(136deg, #ff0021 0%, #ff727d 100%);
            }
            .icon {
              width: 40px;
              height: 40px;
              margin-left: 26px;
            }
            .text {
              display: flex;
              flex-direction: column;
              margin-left: 10px;
              position: relative;
              z-index: 9;
              .tit {
                font-size: 14px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;
                line-height: 20px;
              }
              .txt {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.8);
                line-height: 17px;
              }
            }
            .img {
              width: 134px;
              height: 97px;
              position: absolute;
              right: 10px;
              z-index: 8;
            }
          }
        }
      }
      .store {
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        > div {
          width: 530px;
          height: 510px;
          padding: 10px 17px 0;
        }

        .title {
          width: 100%;
          height: 35px;
          background: #f2f5ff;
          padding: 0 22px 0 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 20px;
          }
        }
        .list {
          width: 100%;
          padding: 0 29px 0 27px;
          ::v-deep .el-table__cell {
            border: none;
          }
          .el-table::before {
            display: none;
          }
          ::v-deep .el-table__cell {
            padding: 10px 0;
          }
          ::v-deep thead {
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 17px;
            .el-table__cell {
              padding: 14px 0 4px;
            }
          }
          .name {
            display: flex;
            align-items: center;
            span {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 17px;
              margin-left: 6px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .text {
            display: flex;
            flex-direction: column;
            span {
              line-height: 17px;
            }
          }
          .phone {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 17px;
          }
        }
        .ranking {
          .list {
            padding: 0;
          }
        }
      }
    }
    .right {
      width: 315px;
      height: 228px;
      margin-right: 40px;
      .top {
        width: 315px;
        height: 114px;
        background: linear-gradient(180deg, #5b4f47 0%, #292930 100%);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffd796;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .come {
          font-size: 14px;
          line-height: 20px;
        }
        .name {
          font-size: 12px;
          line-height: 17px;
          margin: 5px 0 15px;
        }
        .text {
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffd796;
          line-height: 16px;
          span {
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
      .vip-info {
        padding: 15px 20px;
        background: #fffdf7;
        .list {
          display: flex;
          flex-direction: column;
          .item {
            display: flex;
            align-items: center;
            margin-bottom: 14px;
            .item-l {
              display: flex;
              align-items: center;
              flex: 1;
              > img {
                width: 13px;
                height: 13px;
                margin-right: 6px;
              }
              span {
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #935b00;
                line-height: 17px;
              }
            }
            .item-r {
              display: flex;
              align-items: center;
              .txt {
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #00336a;
                line-height: 14px;
              }
              .type {
                height: 18px;
                background: #ffd796;
                border-radius: 10px;
                font-size: 10px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #7f4f00;
                padding: 0 5px;
                margin-left: 5px;
                display: flex;
                align-items: center;
              }
            }
          }
        }
        .button {
          width: 100%;
          height: 25px;
          background: #292930;
          border-radius: 13px;
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffd796;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 14px;
        }
        .intro {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 15px;
          .tit {
            display: flex;
            align-items: center;
            span {
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              line-height: 22px;
              margin: 0 12px;
            }
            .line {
              width: 45px;
              height: 2px;
              background: linear-gradient(270deg, #53535e 0%, rgba(41, 41, 48, 0) 100%);
              border-radius: 2px;
              &:nth-of-type(2) {
                background: linear-gradient(270deg, #53535e 0%, rgba(41, 41, 48, 0) 100%);
              }
            }
          }
          .txt {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #935b00;
            line-height: 17px;
            margin-top: 4px;
          }
          .intro-list {
            width: 100%;
            display: flex;
            justify-content: space-around;
            margin-top: 20px;
            .item {
              display: flex;
              flex-direction: column;
              align-items: center;
              .icon {
                width: 23px;
                height: 23px;
                border-radius: 50%;
                background: #ffffff;
                box-shadow: 0px 0px 4px 0px rgba(211, 200, 166, 0.26);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 7px;
                > img {
                  width: 15px;
                  height: 15px;
                }
              }
              span {
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #935b00;
                line-height: 14px;
              }
            }
          }
          .more {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-top: 20px;
            span {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #935b00;
              line-height: 17px;
            }
            > img {
              width: 12px;
              height: 12px;
              margin-left: 2px;
            }
          }
        }
      }
      .journal {
        width: 315px;
        height: 335px;
        overflow: hidden;
        background: #ffffff;
        margin-top: 10px;
        padding: 18px 11px;
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .text {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 20px;
          }
          .more {
            cursor: pointer;
            display: flex;
            align-items: center;
            span {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #935b00;
              line-height: 17px;
            }
            img {
              width: 12px;
              height: 12px;
              margin-left: 3px;
            }
          }
        }
        .list {
          padding-left: 19px;
          margin-top: 19px;
          overflow-y: scroll;
          height: 300px;
          .item {
            position: relative;
            padding-bottom: 25px;
            &:last-child {
              &:after {
                display: none;
              }
            }
            &:after {
              content: '';
              position: absolute;
              top: 0px;
              left: -12px;
              width: 1px;
              height: 100%;
              background: #ffecb4;
            }
            &:before {
              content: '';
              position: absolute;
              top: 0px;
              left: -16px;
              width: 9px;
              height: 9px;
              border-radius: 50%;
              background: #ffecb4;
            }
            .title {
              display: flex;
              align-items: center;
              .ban {
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
              }
              .time {
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 14px;
              }
            }
            .text {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 17px;
              margin-top: 9px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
        }
      }
    }
  }
}
</style>
